import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, FormFeedback, Input, Table } from "reactstrap";
import Select from "react-select";
import RecurrenceModal from "./RecurrenceModal";
import DailyRecurrenceModal from "./DailyRecurrenceModal";
import MonthlyRecurrenceModal from "./MonthlyRecurrenceModal";
import YearlyRecurrenceModal from "./YearlyRecurrenceModal";
import WeeklyRecurrenceModal from "./WeeklyRecurrenceModal";
import { useSelector } from "react-redux";
import { getVendorDataListAction } from "../../store/Parties/Vendor/action";
const options = [
    { value: "no-repeat", label: "Does not repeat" },
    { value: "weekdays", label: "Every weekday (Mon - Fri)" },
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
];

const channel = [
    { value: "CompanyEmail", label: " Company Email" },
    { value: "MailChimp", label: "MailChimp" },
    { value: "Whatsapp", label: "Whatsapp" },
];


const medium = [
    { value: "Whatsapp", label: "Whatsapp" },
    { value: "Email", label: "Email" },
];

const AddRulePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navigateState = useLocation();
    const [recurrenceModal, setRecurrenceModal] = useState(false)
    const [isDailyModalOpen, setIsDailyModalOpen] = useState(false);
    const [monthlyRecurrenceModal, setMonthlyRecurrenceModal] = useState(false)
    const [yearlyRecurrenceModal, setYearlyRecurrenceModal] = useState(false)
    const [weeklyRecurrenceModal, setWeeklyRecurrenceModal] = useState(false)
    const { sector_master } = useSelector((state) => state?.globalReducer);
    const { vendors_data_list } = useSelector((state) => state?.vendor);
    const [selectedIds, setSelectedIds] = useState([]);

    const handleSelectAll = (e, filteredList) => {
        e.stopPropagation();
        if (e.target.checked) {
            const allIds = filteredList.map((item) => item.id);
            setSelectedIds((prev) => [...new Set([...prev, ...allIds])]);
        } else {
            const filteredIds = filteredList.map((item) => item.id);
            setSelectedIds((prev) =>
                prev.filter((id) => !filteredIds.includes(id))
            );
        }
    };

    const handleCheckboxChange = (id) => {
        console.log(id);

        setSelectedIds(prev =>
            prev.includes(id) ? prev.filter(vendorId => vendorId !== id) : [...prev, id]
        );
    };
    const formik = useFormik({
        initialValues: {
            roleName: "",
            medium: "",
            channel: "",
            startDateTime: new Date().toISOString().slice(0, 16),
            endDateTime: new Date().toISOString().slice(0, 16),
            emailFormat: "",
            frequency: "",
            sector: ""
        },
        onSubmit: (values) => {
            console.log("Form submitted:", values);
        },
    });
    const onCloseClick = () => {
        setRecurrenceModal(false)
        setIsDailyModalOpen(false)
        setMonthlyRecurrenceModal(false)
        setYearlyRecurrenceModal(false)
        setWeeklyRecurrenceModal(false)
    }

    useEffect(() => {
        dispatch(getVendorDataListAction(""));
    }, []);

    return (
        <>
            <div className="page-content settings_users_wrapper">
                <div className="form-container">
                    <button type="button" className="btn border mb-3" onClick={() => { navigate(-1); }} > Back </button>
                    <div className="row">
                        <div className="col-lg-6">
                            <h5 className="mb-3">Add Rule</h5>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Rule Name</label>
                                            <Input
                                                type="text"
                                                name="roleName"
                                                value={formik.values.roleName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"
                                                placeholder="Enter Rule Name"
                                                invalid={formik.touched.roleName && formik.errors.roleName}
                                            />
                                            {formik.touched.roleName && formik.errors.roleName ? (
                                                <FormFeedback>{formik.errors.roleName}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Medium</label>
                                            <Select
                                                name="medium"
                                                options={medium}
                                                value={formik.values.medium}
                                                onChange={(e) => { formik.setFieldValue(`medium`, e); }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select medium"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.medium && formik.errors.medium ? (
                                                <FormFeedback>{formik.errors.medium}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Channel</label>
                                            <Select
                                                name="channel"
                                                options={channel}
                                                value={formik.values.channel}
                                                onChange={(e) => { formik.setFieldValue(`channel`, e); }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select channel"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.channel && formik.errors.channel ? (
                                                <FormFeedback>{formik.errors.channel}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Date Range</label>
                                            <div className="d-flex align-items-center gap-2">
                                                {/* Start Date with Time */}
                                                <Input
                                                    type="datetime-local"
                                                    name="startDateTime"
                                                    value={formik.values.startDateTime}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="form-control"
                                                />
                                                <span>to</span>
                                                {/* End Date with Time */}
                                                <Input
                                                    type="datetime-local"
                                                    name="endDateTime"
                                                    value={formik.values.endDateTime}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className="form-control"
                                                />
                                            </div>
                                            {/* Validation Error Messages */}
                                            {formik.touched.startDateTime && formik.errors.startDateTime && (
                                                <div className="text-danger">{formik.errors.startDateTime}</div>
                                            )}
                                            {formik.touched.endDateTime && formik.errors.endDateTime && (
                                                <div className="text-danger">{formik.errors.endDateTime}</div>
                                            )}
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label">Frequency</label>
                                                <Select
                                                    name="frequency"
                                                    options={options}
                                                    value={formik.values.frequency}
                                                    onChange={(e) => {
                                                        if (e.value == "weekdays") {
                                                            setRecurrenceModal(true)
                                                        } else if (e.value == "daily") {
                                                            setIsDailyModalOpen(true)
                                                        } else if (e.value == "weekly") {
                                                            setWeeklyRecurrenceModal(true)
                                                        } else if (e.value == "monthly") {
                                                            setMonthlyRecurrenceModal(true)
                                                        } else if (e.value == "yearly") {
                                                            setYearlyRecurrenceModal(true)
                                                        }
                                                        formik.setFieldValue(`frequency`, e);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Select Frequency"
                                                    classNamePrefix="select2-selection form-select"
                                                />
                                                {formik.touched.frequency && formik.errors.frequency ? (
                                                    <FormFeedback>{formik.errors.frequency}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Sector</label>
                                            <Select
                                                name="sector"
                                                options={sector_master}
                                                value={formik.values.sector}
                                                onChange={(e) => { formik.setFieldValue(`sector`, e); }}
                                                onBlur={formik.handleBlur}
                                                placeholder="Select Sector"
                                                classNamePrefix="select2-selection form-select"
                                            />
                                            {formik.touched.sector && formik.errors.sector ? (
                                                <FormFeedback>{formik.errors.sector}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label className="form-label">Email Format</label>
                                            <Input
                                                type="textarea"
                                                name="emailFormat"
                                                value={formik.values.emailFormat}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                className="form-control"
                                                rows="4"
                                                placeholder="Enter Email Format"
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        {/* Table Section */}
                        <div className="col-lg-6">
                            <h5>Recipients</h5>
                            <div>
                                <div className="table-responsive">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <Input
                                                        type="checkbox"
                                                        checked={
                                                            vendors_data_list?.content?.filter(
                                                                (data) => data?.country?.sectorMasterId === formik.values.sector?.id
                                                            ).every((item) => selectedIds.includes(item.id))
                                                        }
                                                        onClick={(e) =>
                                                            handleSelectAll(
                                                                e,
                                                                vendors_data_list?.content?.filter(
                                                                    (data) => data?.country?.sectorMasterId === formik.values.sector?.id
                                                                )
                                                            )
                                                        }
                                                    />
                                                </th>
                                                <th>Name</th>
                                                <th>Contact No.</th>
                                                <th>Email ID</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {vendors_data_list?.content?.filter(data => data?.country?.sectorMasterId == formik.values.sector?.id).map((vendor) => (
                                                <tr key={vendor.id}>
                                                    <td>
                                                        <Input
                                                            type="checkbox"
                                                            checked={selectedIds.includes(vendor.id)}
                                                            onClick={() => handleCheckboxChange(vendor.id)}
                                                        />
                                                    </td>
                                                    <td>{vendor.name}</td>
                                                    <td>{vendor.contactNo}</td>
                                                    <td>{vendor.contactEmail}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Buttons */}
                    <div className="d-flex justify-content-center mt-4">
                        <button className="btn btn-primary mx-2" onClick={formik.handleSubmit}>
                            Save
                        </button>
                        <button className="btn btn-secondary mx-2" onClick={formik.resetForm}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <RecurrenceModal modal={recurrenceModal} onCloseClick={onCloseClick} />
            <DailyRecurrenceModal modal={isDailyModalOpen} onCloseClick={onCloseClick} />
            <WeeklyRecurrenceModal modal={weeklyRecurrenceModal} onCloseClick={onCloseClick} />
            <MonthlyRecurrenceModal modal={monthlyRecurrenceModal} onCloseClick={onCloseClick} />
            <YearlyRecurrenceModal modal={yearlyRecurrenceModal} onCloseClick={onCloseClick} />
        </>
    )
}

export default AddRulePage;