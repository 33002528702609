import React from 'react';
import { Modal } from 'reactstrap';

const TransferDialog = ({ isOpen, onClose,onConfirm ,loading ,data,message }) => {
  if (!isOpen) return null;



  return (
    <Modal size="md" isOpen={isOpen} onClose={onClose} className='transferDialog_modal' >
      <div className="modal-header">
        <h1 className='m-0'>Confirmation</h1>
        <button
          onClick={onClose}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className='modal-body'>
        <p>Do you wish to transfer (<span className='transfer_id'>{data}</span>)
          <span className='dark-text'> In Progress</span> to <span className='dark-text'>{message}</span></p>
      </div>
      <div className='modal-footer'>
        <div className="btn_wrap d-flex mt-auto">
          <button className="btn border" type="button" onClick={onClose}>Cancel</button>
          <button className="btn btn-primary" type="button" onClick={onConfirm} disabled={loading}>{loading ? 'Transferring...' : 'Transfer'}</button>
        </div>
      </div>

    </Modal>
  );
};


export default TransferDialog

