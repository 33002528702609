import Nouislider from 'nouislider-react';
import "nouislider/distribute/nouislider.css";
import React, { useCallback, useEffect, useState } from 'react';
import Select from "react-select";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import approveIcon from'../../../assets/images/icons/approve-icon.jpeg'
import sentIcon from'../../../assets/images/icons/sent-icon.jpeg'
import createIcon from'../../../assets/images/icons/created-icon.jpeg'
import { formatDate } from '../../../components/Common/CommonLogic';

export default function SalesHistoryComp({ isRight, previewModalHand, filterDetails, setfilterDetails, applyFilterHandler, clearValueHandler }) {
    const { quotation_history_data, } = useSelector((state) => state?.sales);

    const [rangeValues, setRangeValues] = useState([45, 2500]); // Initial values for the range slider
    const [dateRange, setDateRange] = useState([new Date(2023, 0, 1), new Date(2023, 10, 5)]);
    const { vendor_data, cargoType_data, container_data } = useSelector((state) => state.globalReducer);
    const [optionVendorName, setOptionVendorName] = useState([]);
    const [optionCarrierName, setOptionCarrierName] = useState([]);
    // const formatDate = (date) => {
    //     // Format date as needed (e.g., "MM/DD/YYYY")
    //     const options = { year: 'numeric', month: 'short', day: '2-digit' };
    //     return date.toLocaleDateString('en-GB', options);
    // };

    const handleRangeChange = (values, handle) => {
        let newObj = {
            ...filterDetails,
            quote_value: values
        }
        setRangeValues(values);
        setfilterDetails(newObj);
    };

    const handleDateChange = (values, handle) => {
        let newDate = values.map((value) => new Date(Number(value)));
        setDateRange(newDate);
        let newObj = {
            ...filterDetails,
            expiration_date: newDate
        }
        setfilterDetails(newObj);
    };

    const handleSelectGroup = useCallback((name, opt) => {
        let newObj = {
            ...filterDetails,
            [name]: opt
        }
        setfilterDetails(newObj);
    }, [filterDetails]);

    useEffect(() => {
        let vendorlist = vendor_data?.content?.map((item) => {
            return { label: item?.name, value: item?.name, version: item?.version, id: item?.id, type: item?.vendorType, transportMode: item?.transportMode }
        });
        let carrierList = vendorlist?.filter((item) => item?.type === "CARRIER" && item?.transportMode == 'OCEAN');
        let vendorNewList = vendorlist?.filter((item) => item?.type !== "CARRIER" && item?.transportMode == 'OCEAN');
        setOptionVendorName(vendorNewList);
        setOptionCarrierName(carrierList);
    }, [vendor_data]);

    console.log("quotation_history_data",quotation_history_data)
    return (
        <>
            <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={previewModalHand} className="history_modal">
                <OffcanvasHeader toggle={previewModalHand} className="modal_title">
                    History
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form className='h-100 position-relative'>
                        <div className="fcl_filter_sidebar_wrap sales_filter_wrap d-flex flex-column">
                            {quotation_history_data.map((item, index) => (
                                
                                <div key={index} className="timeline-item d-flex mb-4">
                                    <div className="timeline-icon">
                                        {/* <img src={item.icon} /> */}
                                    </div>
                                    <div className="ms-3">
                                        <div className="timeline-content d-flex">
                                            <h6 className="fw-bold timeline_title pe-1">{item?.statusName} </h6>
                                            <p className="timeline_dec mb-1">
                                            for the #{item?.sequenceId}
                                            </p>
                                        </div>
                                            <small className="text-muted">{item.modifiedByName} • {formatDate(item.modifiedDate)}</small> 
                                    </div>

                                </div>
                            ))}
                        </div>
                        <div className="btn_wrap d-flex mt-auto position-absolute bottom-0 w-100">
                            <button className='btn border w-100' type='button' onClick={previewModalHand}>Close</button>
                        </div>
                    </form>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}
