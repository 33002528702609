import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Form, Input } from "reactstrap";

const WeeklyRecurrenceModal = ({ modal, onCloseClick }) => {
    const [recurrenceData, setRecurrenceData] = useState({
        start: "",
        repeatEvery: 1,
        repeatType: "Week",
        selectedDays: [],
        end: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRecurrenceData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDayToggle = (day) => {
        setRecurrenceData((prevState) => {
            const days = prevState.selectedDays.includes(day)
                ? prevState.selectedDays.filter((d) => d !== day)
                : [...prevState.selectedDays, day];
            return { ...prevState, selectedDays: days };
        });
    };

    const clearSelectedDays = () => {
        setRecurrenceData((prevState) => ({
            ...prevState,
            selectedDays: [],
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (recurrenceData.selectedDays.length === 0) {
            alert("Please select at least one day!");
            return;
        }
        console.log("Weekly Recurrence Data:", recurrenceData);
        onCloseClick(); // Close modal
    };

    return (
        <Modal isOpen={modal} toggle={onCloseClick}>
            <ModalHeader tag="h4">
                Set Weekly Recurrence
                <span className="close" onClick={onCloseClick}></span>
            </ModalHeader>
            <ModalBody>
                <Form className="custom-form mt-2 p-1" onSubmit={handleSubmit}>
                    {/* Start Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">Start</label>
                            <Input
                                type="date"
                                name="start"
                                value={recurrenceData.start}
                                onChange={handleChange}
                                className="form-control"
                                required
                            />
                        </div>
                    </div>

                    {/* Repeat Every */}
                    <div className="row mb-4">
                        <div className="col-6">
                            <label className="form-label">Repeat every</label>
                            <Input
                                type="number"
                                name="repeatEvery"
                                value={recurrenceData.repeatEvery}
                                onChange={handleChange}
                                className="form-control"
                                min="1"
                                required
                            />
                        </div>
                        <div className="col-6">
                            <label className="form-label">Type</label>
                            <Input
                                type="select"
                                name="repeatType"
                                value={recurrenceData.repeatType}
                                onChange={handleChange}
                                className="form-control"
                                disabled
                            >
                                <option value="Week">Week</option>
                            </Input>
                        </div>
                    </div>

                    {/* Select Days */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">Select Days</label>
                            <div className="d-flex justify-content-between">
                                {["S", "M", "Tu", "W", "Th", "F", "Sa"].map((day) => (
                                    <button
                                        key={day}
                                        type="button"
                                        className={`btn ${
                                            recurrenceData.selectedDays.includes(day)
                                                ? "btn-primary"
                                                : "btn-outline-primary"
                                        }`}
                                        onClick={() => handleDayToggle(day)}
                                    >
                                        {day}
                                    </button>
                                ))}
                            </div>
                            <button
                                type="button"
                                className="btn btn-link mt-2"
                                onClick={clearSelectedDays}
                            >
                                Clear Days
                            </button>
                        </div>
                    </div>

                    {/* End Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">End</label>
                            <Input
                                type="date"
                                name="end"
                                value={recurrenceData.end}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Summary */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <p className="text-muted">
                                Occurs every {recurrenceData.repeatEvery} week(s) on{" "}
                                {recurrenceData.selectedDays.length > 0
                                    ? recurrenceData.selectedDays.join(", ")
                                    : "no days selected"}
                                , starting {recurrenceData.start || "Select a start date"}.
                            </p>
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="row">
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary mx-3" type="submit">
                                Save
                            </button>
                            <button
                                className="btn btn-secondary mx-3"
                                type="button"
                                onClick={() => {
                                    onCloseClick();
                                    setRecurrenceData({
                                        start: "",
                                        repeatEvery: 1,
                                        repeatType: "Week",
                                        selectedDays: [],
                                        end: "",
                                    });
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default WeeklyRecurrenceModal;
