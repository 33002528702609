import React from 'react'
import { Container } from 'reactstrap'

export default function ExpressComp() {
  return (
    <React.StrictMode>
        <div className="page-content">
            <Container fluid>Express</Container>
        </div>
    </React.StrictMode>
  )
}
