import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Form, Input } from "reactstrap";

const YearlyRecurrenceModal = ({ modal, onCloseClick }) => {
    const [recurrenceData, setRecurrenceData] = useState({
        start: "",
        repeatEvery: 1, // Frequency in years
        recurrenceType: "specific", // 'specific' or 'pattern'
        specificMonth: "January", // Month for 'specific' option
        specificDay: 1, // Day for 'specific' option
        weekIndex: "First", // For 'pattern' option
        weekDay: "Monday", // For 'pattern' option
        patternMonth: "January", // Month for 'pattern' option
        end: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRecurrenceData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Recurrence Data:", recurrenceData);
        onCloseClick(); // Close the modal
    };

    return (
        <Modal isOpen={modal} toggle={onCloseClick}>
            <ModalHeader tag="h4">
                Set Recurrence
                <span className="close" onClick={onCloseClick}></span>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="custom-form mt-2 p-1"
                    onSubmit={handleSubmit}
                >
                    {/* Start Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">Start</label>
                            <Input
                                type="date"
                                name="start"
                                value={recurrenceData.start}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Repeat Every */}
                    <div className="row mb-4">
                        <div className="col-6">
                            <label className="form-label">Repeat every</label>
                            <Input
                                type="number"
                                name="repeatEvery"
                                value={recurrenceData.repeatEvery}
                                onChange={handleChange}
                                className="form-control"
                                min="1"
                            />
                        </div>
                        <div className="col-6">
                            <label className="form-label">Type</label>
                            <Input
                                type="select"
                                name="recurrenceType"
                                value={recurrenceData.recurrenceType}
                                onChange={handleChange}
                                className="form-control"
                            >
                                <option value="specific">On specific date</option>
                                <option value="pattern">On specific pattern</option>
                            </Input>
                        </div>
                    </div>

                    {/* Specific Date */}
                    {recurrenceData.recurrenceType === "specific" && (
                        <div className="row mb-4">
                            <div className="col-6">
                                <label className="form-label">Month</label>
                                <Input
                                    type="select"
                                    name="specificMonth"
                                    value={recurrenceData.specificMonth}
                                    onChange={handleChange}
                                    className="form-control"
                                >
                                    {[
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                    ].map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </Input>
                            </div>
                            <div className="col-6">
                                <label className="form-label">Day</label>
                                <Input
                                    type="number"
                                    name="specificDay"
                                    value={recurrenceData.specificDay}
                                    onChange={handleChange}
                                    className="form-control"
                                    min="1"
                                    max="31"
                                />
                            </div>
                        </div>
                    )}

                    {/* Pattern */}
                    {recurrenceData.recurrenceType === "pattern" && (
                        <div className="row mb-4">
                            <div className="col-4">
                                <label className="form-label">Week</label>
                                <Input
                                    type="select"
                                    name="weekIndex"
                                    value={recurrenceData.weekIndex}
                                    onChange={handleChange}
                                    className="form-control"
                                >
                                    <option value="First">First</option>
                                    <option value="Second">Second</option>
                                    <option value="Third">Third</option>
                                    <option value="Fourth">Fourth</option>
                                    <option value="Last">Last</option>
                                </Input>
                            </div>
                            <div className="col-4">
                                <label className="form-label">Day</label>
                                <Input
                                    type="select"
                                    name="weekDay"
                                    value={recurrenceData.weekDay}
                                    onChange={handleChange}
                                    className="form-control"
                                >
                                    <option value="Sunday">Sunday</option>
                                    <option value="Monday">Monday</option>
                                    <option value="Tuesday">Tuesday</option>
                                    <option value="Wednesday">Wednesday</option>
                                    <option value="Thursday">Thursday</option>
                                    <option value="Friday">Friday</option>
                                    <option value="Saturday">Saturday</option>
                                </Input>
                            </div>
                            <div className="col-4">
                                <label className="form-label">Month</label>
                                <Input
                                    type="select"
                                    name="patternMonth"
                                    value={recurrenceData.patternMonth}
                                    onChange={handleChange}
                                    className="form-control"
                                >
                                    {[
                                        "January",
                                        "February",
                                        "March",
                                        "April",
                                        "May",
                                        "June",
                                        "July",
                                        "August",
                                        "September",
                                        "October",
                                        "November",
                                        "December",
                                    ].map((month) => (
                                        <option key={month} value={month}>
                                            {month}
                                        </option>
                                    ))}
                                </Input>
                            </div>
                        </div>
                    )}

                    {/* End Date */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <label className="form-label">End</label>
                            <Input
                                type="date"
                                name="end"
                                value={recurrenceData.end}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {/* Actions */}
                    <div className="row">
                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary mx-3" type="submit">
                                Save
                            </button>
                            <button
                                className="btn btn-secondary mx-3"
                                type="button"
                                onClick={() => {
                                    onCloseClick();
                                    setRecurrenceData({
                                        start: "",
                                        repeatEvery: 1,
                                        recurrenceType: "specific",
                                        specificMonth: "January",
                                        specificDay: 1,
                                        weekIndex: "First",
                                        weekDay: "Monday",
                                        patternMonth: "January",
                                        end: "",
                                    });
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default YearlyRecurrenceModal;
