import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Modal } from 'reactstrap';
import { updateQuotionAssingToData } from '../../../../store/Sales/actions';
import { showErrorToast } from '../../../../components/Common/CustomToast';
import { MANAGER_APPRVD, MANAGER_REJECT } from '../../../Dashboard/Partials/initialData';

const ApproveRejectPopUp = ({ isOpen, onClose, onConfirm, selectedRows, data }) => {
    if (!isOpen) return null;
    const { status_master } = useSelector(state => state?.globalReducer);

    const [remarks, setRemarks] = useState('');
    const dispatch = useDispatch();

    const onClickSave = () => {
        if (!remarks.trim()) {
            showErrorToast('Please enter remarks');
        } else {
            let paload = {
                remarks: remarks.trim(),
                statusId:data=='Approve' ?status_master?.find(status => String(status?.statusCode) === MANAGER_APPRVD).id:status_master?.find(status => String(status?.statusCode) === MANAGER_REJECT).id,
                quotationsIds: selectedRows
            }
            dispatch(updateQuotionAssingToData(paload));
            onClose();
        }
    };

    return (
        <Modal size="md" isOpen={isOpen} onClose={onClose} className="transferDialog_modal">
            <div className="modal-header">
                <h1 className="m-0">{data}</h1>
                <button
                    onClick={onClose}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Enter remarks here..."
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                />
            </div>
            <div className="modal-footer">
                <div className="btn_wrap d-flex mt-auto">
                    <button className="btn border" type="button" onClick={onClose}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" type="button" onClick={onClickSave}>
                        Save
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ApproveRejectPopUp;
