import Nouislider from 'nouislider-react';
import "nouislider/distribute/nouislider.css";
import React, { useCallback, useEffect, useState } from 'react';
import Select from "react-select";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';
import { optionDestQuote, optionModeQuote, optionOriginQuote, optionStatusQuote } from '../../../common/data/sales';
import { useSelector } from 'react-redux';

export default function FilterSalesComp({ isRight, toggleRightCanvas, filterDetails, setfilterDetails, applyFilterHandler, clearValueHandler }) {
    const [rangeValues, setRangeValues] = useState([45, 2500]); // Initial values for the range slider
    const [dateRange, setDateRange] = useState([new Date(2023, 0, 1), new Date(2023, 10, 5)]);
    const { vendor_data,cargoType_data,container_data, status_master,oceanPort_data } = useSelector((state) => state.globalReducer);
    const [optionVendorName, setOptionVendorName] = useState([]);
    const [optionCarrierName, setOptionCarrierName] = useState([]);
    const formatDate = (date) => {
        // Format date as needed (e.g., "MM/DD/YYYY")
        const options = { year: 'numeric', month: 'short', day: '2-digit' };
        return date.toLocaleDateString('en-GB', options);
    };

    const handleRangeChange = (values, handle) => {
        let newObj = {
            ...filterDetails,
            quote_value: values
        }
        setRangeValues(values);
        setfilterDetails(newObj);
    };

    const handleDateChange = (values, handle) => {
        let newDate = values.map((value) => new Date(Number(value)));
        setDateRange(newDate);
        let newObj = {
            ...filterDetails,
            expiration_date: newDate
        }
        setfilterDetails(newObj);
    };

    const handleSelectGroup = useCallback((name, opt) => {
        let newObj = {
            ...filterDetails,
            [name]: opt
        }
        setfilterDetails(newObj);
    }, [filterDetails]);

    useEffect(() => {
        let vendorlist = vendor_data?.content?.map((item) => {
          return { label: item?.name, value: item?.name, version: item?.version, id: item?.id, type: item?.vendorType, transportMode: item?.transportMode }
        });
        let carrierList = vendorlist?.filter((item) => item?.type === "CARRIER" && item?.transportMode == 'OCEAN');
        let vendorNewList = vendorlist?.filter((item) => item?.type !== "CARRIER" && item?.transportMode == 'OCEAN');
        setOptionVendorName(vendorNewList);
        setOptionCarrierName(carrierList);
      }, [vendor_data]);

    return (
        <>
            <Offcanvas
                isOpen={isRight}
                direction="end"
                toggle={toggleRightCanvas}>
                <OffcanvasHeader toggle={toggleRightCanvas}>
                    Filter
                </OffcanvasHeader>
                <OffcanvasBody>
                    <form className='h-100'>
                        <div className="fcl_filter_sidebar_wrap sales_filter_wrap d-flex flex-column h-100">
                            <div className="row">
                               
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor='quotation_from' >Quotation From</label>
                                        <input type="date" name="quotation_from" id="quotation_from" className='form-control' value={filterDetails.quotation_from} onChange={(e) => handleSelectGroup('quotation_from', e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label htmlFor='quotation_to' >Quotation To</label>
                                        <input type="date" name="quotation_to" id="quotation_to" className='form-control' value={filterDetails.quotation_to} onChange={(e) => handleSelectGroup('quotation_to', e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label >Origin Port</label>
                                        <Select
                                            value={filterDetails.org_port}
                                            name='org_port'
                                            onChange={(opt) => {
                                                handleSelectGroup('org_port', opt);
                                            }}
                                            options={oceanPort_data}
                                            placeholder={'Select Origin'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label>Destination Port</label>
                                        <Select
                                            value={filterDetails.dest_port}
                                            name='dest_port'
                                            onChange={(opt) => {
                                                handleSelectGroup('dest_port', opt);
                                            }}
                                            options={oceanPort_data}
                                            placeholder={'Select Destination'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label >Carrier Name</label>
                                        <Select
                                            value={filterDetails.carrier_name}
                                            name='carrier_name'
                                            onChange={(opt) => {
                                                handleSelectGroup('carrier_name', opt);
                                            }}
                                            options={optionCarrierName}
                                            placeholder={'Carrier Name'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label >Vendor Name</label>
                                        <Select
                                            value={filterDetails.vendor_name}
                                            name='vendor_name'
                                            onChange={(opt) => {
                                                handleSelectGroup('vendor_name', opt);
                                            }}
                                            options={optionVendorName}
                                            placeholder={'Vendor Name'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label >Cargo Type</label>
                                        <Select
                                            value={filterDetails.cargo_type}
                                            name='cargo_type'
                                            onChange={(opt) => {
                                                handleSelectGroup('cargo_type', opt);
                                            }}
                                            options={cargoType_data.filter(data => data?.transportMode == "OCEAN")}
                                            placeholder={'Select Cargo Type'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">

                                        <label>Container Type</label>
                                        <Select
                                            value={filterDetails.container_type}
                                            name='container_type'
                                            onChange={(opt) => {
                                                handleSelectGroup('container_type', opt);
                                            }}
                                            options={container_data}
                                            placeholder={'Select Status'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">

                                        <label >Quotation Status</label>
                                        <Select
                                            value={filterDetails.quote_status}
                                            name='quote_status'
                                            onChange={(opt) => {
                                                handleSelectGroup('quote_status', opt);
                                            }}
                                            options={status_master}
                                            placeholder={'Select Status'}
                                            classNamePrefix="select2-selection form-select"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="btn_wrap d-flex mt-auto">
                                <button className='btn border' type='button' onClick={() => { setRangeValues([45, 2500]); setDateRange([new Date(2023, 0, 1), new Date(2023, 10, 5)]); clearValueHandler(); }}>Clear</button>
                                <button className='btn btn-primary' type='button' onClick={applyFilterHandler}>Apply Filter</button>
                            </div>
                        </div>
                    </form>
                </OffcanvasBody>
            </Offcanvas>
        </>
    )
}
