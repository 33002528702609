import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Container
} from "reactstrap";
import { CommonValue } from "../Parties/PartiesCol";
import { Edit } from "../Settings/SettingsCol";
import LeadGenarationListingTable from "./LeadGenarationListingTable";
import { LeadGenarationBreadcrumb, RolesBreadcrumb } from "../../common/data/parties";
import TopBreadcrumbs from "../Settings/Surcharge/TopBreadcrumbs";
import TfBreadcrumbs from "../../components/Common/TfBreadcrumbs";

const LeadGenarationListingPage = () => {
    const [modal, setModal] = useState(false);
    const [viewData, setViewData] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(0);

    const { customer_data, customer_loader } = useSelector((state) => state?.customer);


    const editHandler = (data) => {
        console.log(data);
        navigate(`/customers/add-customer`, {
            state: {
                id: data?.id || '',
                data: data
            },
        });
    };
    const viewPopupHandler = (data) => {
        setModal(true);
        setViewData(data);
    };

    useEffect(() => {
        // dispatch(getCustomersCityData())
    }, [dispatch]);

    //   useEffect(() => {
    //     if(currentPage !== '' && currentPage !== undefined){
    //       let url = `?page=${currentPage}&size=10`;
    //       dispatch(getAllPartiesCustomerData(url))
    //     }
    //   }, [dispatch,currentPage]);

    const columns = useMemo(
        () => [
            {
                Header: "Rule Id",
                accessor: "customerCode",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Rule Name",
                accessor: "name",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Boradcast Medium",
                accessor: "type",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Channel",
                accessor: "contactName",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Start Date",
                accessor: "contactNo",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "End Date",
                accessor: "endDate",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Repeat",
                accessor: "repeat",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Sector",
                accessor: "sector",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Country",
                accessor: "country",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Total Recipients",
                accessor: "recipients",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Email Format",
                accessor: "email",
                filterable: true,
                disableFilters: true,
                Cell: (cellProps) => {
                    return (
                        <CommonValue
                            cellProps={cellProps}
                            viewPopupHandler={viewPopupHandler}
                        />
                    );
                },
            },
            {
                Header: "Edit",
                Cell: (cellProps) => {
                    return <Edit cellProps={cellProps} viewPopupHandler={editHandler} />
                },
            },
        ],
        []
    );

    return (
        <>
            <div className="page-content settings_users_wrapper">
                {/* <TopBreadcrumbs breadcrumbs={LeadGenarationBreadcrumb} /> */}
                <Container fluid>
                    <div className="main_freight_wrapper">
                        {/* <div className="summary-wrapper mb-3">
                            <div className="summary-box">
                                <p className="summary-title">Total Active Ports</p>
                                <div className="summary-content">
                                    <span className="summary-value">{customer_data?.totalActivePorts || 0}</span>
                                    <span className="summary-change positive">
                                        <span className="arrow-up">▲</span>
                                        {customer_data?.totalActivePortsChange || 0}%
                                    </span>
                                </div>
                            </div>
                            <div className="summary-box">
                                <p className="summary-title">Rate Density</p>
                                <div className="summary-content">
                                    <span className="summary-value">{customer_data?.rateDensity || 0}</span>
                                    <span className="summary-change positive">
                                        <span className="arrow-up">▲</span>
                                        {customer_data?.rateDensityChange || 0}%
                                    </span>
                                </div>
                            </div>
                            <div className="summary-box">
                                <p className="summary-title">Avg Conversion</p>
                                <div className="summary-content">
                                    <span className="summary-value">{customer_data?.avgConversion || 0}</span>
                                    <span className="summary-change positive">
                                        <span className="arrow-up">▲</span>
                                        {customer_data?.avgConversionChange || 0}%
                                    </span>
                                </div>
                            </div>
                        </div> */}

                        <div className="tf_top_breadcrumb_rate_wrap">
                            <TfBreadcrumbs breadcrumb={LeadGenarationBreadcrumb} />
                            <div className="tf_box_wrap d-flex">
                            <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Total Active Ports</p>
                                    <div className="sh_inquiry_rate">{customer_data?.actionedCount}
                                        {customer_data?.compare_rate !== '' ? (
                                            <span className={`${customer_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{customer_data?.actionedCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Rate Density</p>
                                    <div className="sh_inquiry_rate">{customer_data?.actionedCount}
                                        {customer_data?.compare_rate !== '' ? (
                                            <span className={`${customer_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{customer_data?.actionedCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="sh_box flex-grow-1" >
                                    <p className="box_title">Avg Conversion</p>
                                    <div className="sh_inquiry_rate">{customer_data?.pendingCount}
                                        {customer_data?.compare_rate !== '' ? (
                                            <span className={`${customer_data?.rate_type === 'down' ? 'red_text' : 'green_text'}`}>{customer_data?.pendingCountPercentage}%</span>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LeadGenarationListingTable
                            columns={columns}
                            data={[]}
                            isGlobalFilter={true}
                            isAddInvoiceList={true}
                            customPageSize={10}
                            component={"Customers"}
                            loader={customer_loader || false}
                            setCurrentPage={setCurrentPage}
                            totalPages={customer_data?.totalPages || 0}
                            totalEntries={customer_data?.totalElements || 0}
                        />
                    </div>
                </Container>
            </div>
        </>
    );
};

export default LeadGenarationListingPage;
