import React from 'react'
import { Container } from 'reactstrap'

export default function AnalyticsComp() {
  return (
    <React.StrictMode>
        <div className="page-content">
            <Container fluid>Analytics</Container>
        </div>
    </React.StrictMode>
  )
}
